import { ShipmentStatus } from '~/features/shipments/shipment';
import { captureException } from '~/utils/exception-tracking';
import { unique } from '~/utils/functions';

export const SubscriptionStatus = {
  LEAD: '', // Not an API enum value, used on the FE only
  SCHEDULED: 'sch', // Will be reauthorized on the scheduled date and transition to ACTIVE
  ACTIVE: 'act',
  CANCELLED: 'can',
  AUTH_FAIL: 'aut', // The attempt to charge the user failed
  COMPLETED: 'com',
  DO_NOT_RENEW: 'dnr',
};

/**
 * Convert an API subscription status enum to a display value
 */
export const subscriptionStatusDisplay = (status) => {
  switch (status) {
    case SubscriptionStatus.LEAD:
      return 'lead';
    case SubscriptionStatus.SCHEDULED:
      return 'scheduled';
    case SubscriptionStatus.ACTIVE:
      return 'active';
    case SubscriptionStatus.CANCELLED:
      return 'cancelled';
    case SubscriptionStatus.AUTH_FAIL:
      return 'auth fail';
    case SubscriptionStatus.COMPLETED:
      return 'completed';
    case SubscriptionStatus.DO_NOT_RENEW:
      return 'do not renew';
    default:
      return status;
  }
};

/**
 * Subscription type as defined by the API
 */
export const SubscriptionItemType = {
  LAWN_PLAN: 'law',
  SEED: 'seed', // To handle seed care actions
  MOSQUITO: 'mos',
  TOTAL_HOME: 'bar',
  TICK: 'tic',
  PEST: 'pes',
  SPRING: 'spr',
  BASIC: 'bas', // Any add-on product subscription
};

/**
 * Subscription name as defined by the API
 */
export const subscriptionName = {
  LAWN: 'Lawn Subscription',
  PEST: 'Pest Subscription',
  MOSQUITO: 'Mosquito Protection Plan',
  TICK: 'Tick Protection Plan',
  TOTAL_HOME: 'Total Home Protection Plan',
};

/**
 * Convert an API subscription type enum to a display value
 */
export const subscriptionTypeDisplay = (type) => {
  switch (type) {
    case SubscriptionItemType.LAWN_PLAN:
      return 'Lawn';
    case SubscriptionItemType.SEED:
      return 'Seed';
    case SubscriptionItemType.PEST:
      return 'Pest';
    case SubscriptionItemType.MOSQUITO:
      return 'Mosquito';
    case SubscriptionItemType.TICK:
      return 'Tick';
    case SubscriptionItemType.TOTAL_HOME:
      return 'Total Home';
    case SubscriptionItemType.BASIC:
      return 'Add-on';
    default:
      return type;
  }
};

/**
 * Cancellation type as defined by the API
 */
export const CancellationType = {
  CANCEL_NOW: 'can',
  DO_NOT_RENEW: 'don',
  FREE_PLAN: 'fre',
};

export const subscriptionsToSummaries = (subscriptions, shipments) => {
  if (!subscriptions || !shipments || subscriptions.length === 0) {
    return [];
  }

  return subscriptions
    .map((subscription) => {
      const subscriptionItemYears = subscription.subscriptionItems.map(
        (subItem) => subItem.year
      );

      const dedupedYears = unique(subscriptionItemYears).sort();

      const subscriptionItemsByYear = subscription.subscriptionItems.reduce(
        (acc, curr) => {
          if (acc[curr.year]) {
            return {
              ...acc,
              [curr.year]: [...acc[curr.year], curr],
            };
          }

          return {
            ...acc,
            [curr.year]: [curr],
          };
        },
        {}
      );

      return dedupedYears.map((year) => {
        const subscriptionItems = subscriptionItemsByYear[year];
        const status = deriveSubscriptionStatus(subscription, year);

        // CANCELLED subscription items can't be trusted when doing
        // anything other than determining a parent subscription's
        // cancellation date
        const uncancelledSubscriptionItems = subscriptionItems.filter(
          (item) => item.status !== SubscriptionStatus.CANCELLED
        );

        return {
          ...subscription,
          status,
          year,
          cancelAt:
            status === SubscriptionStatus.CANCELLED
              ? mostRecentDate(subscriptionItems.map((item) => item.cancelAt))
              : null,
          purchaseDate: mostRecentDate(
            uncancelledSubscriptionItems.map((item) => item.purchaseDate)
          ),
          shipments: shipments
            .filter((shipment) => shipment.status !== ShipmentStatus.CANCELLED)
            .filter((shipment) =>
              shipment.items.some((shipmentItem) =>
                uncancelledSubscriptionItems
                  .map((subItem) => subItem.uuid)
                  .includes(shipmentItem.subscriptionUuid)
              )
            ),
          subscriptionItems: uncancelledSubscriptionItems,
        };
      });
    })
    .flat();
};

/**
 * A subscription's `status` is determined by its children
 * subscription items. The subscription items for the given
 * year should either:
 * - All have the same status,
 * - Be a mix of CANCELLED + one other status
 *
 * The mix of statuses can happen when a "basic"
 * subscription item is cancelled but the lawn plan isn't.
 * In that scenario the "basic" item is set to CANCELLED
 * (even though it hasn't been paid for), but the lawn plan
 * is still SCHEDULED. The overall subscription
 * should be considered as whatever the non-CANCELLED
 * status is, which would be SCHEDULED in that scenario.
 *
 * I'm sorry.
 */
function deriveSubscriptionStatus(subscription, year) {
  if (!subscription?.subscriptionItems) {
    return null;
  }

  const uniqueStatuses = unique(
    subscription.subscriptionItems
      .filter((item) => item.year === year)
      .map((item) => item.status)
  );

  if (uniqueStatuses.length === 1) {
    return uniqueStatuses[0];
  }

  // If there are multiple statuses then it *should* only
  // be CANCELLED + *something else* and our unique array should
  // only have two items. If it doesn't then that's a data
  // integrity problem that we're not going to account for.
  // We will track it in Sentry though.
  if (uniqueStatuses.length > 2) {
    captureException(
      new Error('More than 2 unique statuses found for a subscription'),
      {
        extras: {
          subscriptionUuid: subscription.uuid,
          uniqueStatuses,
        },
      }
    );
  }

  return uniqueStatuses.find(
    (status) => status !== SubscriptionStatus.CANCELLED
  );
}

function mostRecentDate(dates = []) {
  const byDateDesc = (a, b) => {
    return new Date(a) > new Date(b) ? -1 : 1;
  };

  return [...dates].sort(byDateDesc)[0];
}

/**
 * Sort subscription items by year
 */
export const sortSubscriptionItems = (subscription) => {
  // the || piece in case sub?.subItems is falsey
  const subscriptionItems = [...(subscription?.subscriptionItems || [])];
  return subscriptionItems.sort((a, b) => a.year - b.year);
};

/**
 *
 * @param {Array} subscriptions
 * @param {String} status
 * @param {Number} minYear
 *
 * Get the lawn subscription item by status.
 * The function will return the lawn subscription item that matches the given status.
 * If minYear is provided, the function will return the lawn subscription item that matches the status and has a year greater than or equal to minYear.
 */
export const getLawnSubscriptionItemByStatus = (
  subscriptions,
  { status, minYear }
) => {
  const activeSubscription = subscriptions?.find((subscription) =>
    sortSubscriptionItems(subscription)?.some((item) => {
      return (
        item.planType === SubscriptionItemType.LAWN_PLAN &&
        item.status === status &&
        (minYear ? item.year >= minYear : true)
      );
    })
  );

  return activeSubscription?.subscriptionItems?.find((item) => {
    return (
      item.planType === SubscriptionItemType.LAWN_PLAN &&
      item.status === status &&
      (minYear ? item.year >= minYear : true)
    );
  });
};
